import React from 'react';

type Props = {
  platform: string
}

const SocialButton: React.FC<Props> = ({ platform }) => {
  const platformLinks: any = {
    'Twitter': {href: 'https://twitter.com/UnlikelySurvivor', title: 'Follow me on Twitter for Streaming updates'},
    'Youtube': {href: 'https://www.youtube.com/channel/UCTDyBk-icccyWJpBRzE6zvQ', title: 'Subscribe to UnlikelySurvivor content'},
    'Twitch': {href: 'https://twitch.tv/UnlikelySurvivor', title: 'Watch UnlikelySurvivor on Twitch'},
    // 'Discord': {href: 'https://discordapp.com/invite/fyhtTYR', title: 'Join the conversation with UnlikelySurvivor on Discord'}
  };
  return (
    <a {...platformLinks[platform]} className="" target="_blank">
      <img src={`/images/Icon-${platform}.png`} alt="" style={{width: '125px', height: '125px'}} />
    </a>
  );
};

export default SocialButton;